exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-certified-medical-center-tsx": () => import("./../../../src/pages/certified-medical-center.tsx" /* webpackChunkName: "component---src-pages-certified-medical-center-tsx" */),
  "component---src-pages-experts-tsx": () => import("./../../../src/pages/experts.tsx" /* webpackChunkName: "component---src-pages-experts-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-micro-toxin-treatment-tsx": () => import("./../../../src/pages/micro-toxin-treatment.tsx" /* webpackChunkName: "component---src-pages-micro-toxin-treatment-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

